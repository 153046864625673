import type { LocationsServiceItem } from './types'

export const useDelete = (props: {
  onSelect: () => unknown
  onSuccess: () => unknown
}) => {
  const selected = ref<LocationsServiceItem | null>(null)

  const { processing, action: submit } = useRequest(async (api) => {
    await api.locations.deleteLocation({ id: selected.value?.id || '' })
    await props.onSuccess()
  })

  const select = (room: LocationsServiceItem | null): void => {
    selected.value = room
    if (room) props.onSelect()
  }

  const cancel = (): void => {
    selected.value = null
  }

  return {
    data: reactive({
      selected,
      name: computed<string>(() => selected.value?.name || ''),
    }),

    state: reactive({
      isSubmitting: processing,
    }),

    actions: {
      select,
      cancel,
      submit,
    },
  }
}
