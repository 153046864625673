import { useDetails } from './useDetails'
import { useCreate } from './useCreate'
import { useDelete } from './useDelete'
import { useList } from './useList'
import { useOptions } from './useOptions'

export class LocationsService {
  public static useList = useList

  public static useDetails = useDetails

  public static useCreate = useCreate

  public static useDelete = useDelete

  public static useOptions = useOptions
}
