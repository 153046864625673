import type { ApiError, DtoLocation } from '../sdk/api'
import type { LocationsServiceItem } from './types'

export const useList = async () => {
  const api = useAPI()
  const { $link } = useNuxtApp()

  const { data, pending, error, refresh } = await useAsyncData<
    DtoLocation[],
    ApiError,
    DtoLocation[]
  >('locations', () => api.locations.getLocations(), {
    default: () => [],
  })

  const items = computed<LocationsServiceItem[]>(() => {
    return data.value
      .map((location) => ({
        ...location,
        href: $link.locations.details(location.id),
      }))
      .sort((prev, next) => prev.name.localeCompare(next.name))
  })

  return {
    data: reactive({
      items,
      error,
    }),

    state: reactive({
      pending,
    }),

    actions: {
      refresh,
    },
  }
}
