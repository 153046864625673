import type { DtoLocationDetails } from '../sdk/api'
import type { AsyncDataError } from '~/types'

export const useDetails = async (
  id: string,
  props?: Partial<{ onSuccess(): unknown }>,
) => {
  const api = useAPI()

  const name = useStringField({ required: true })
  const address = useStringField()

  const { data, pending, refresh, error } = await useAsyncData<
    DtoLocationDetails,
    AsyncDataError
  >('location-details', () => api.locations.getLocationById({ id }))

  const { processing, action: update } = useRequest(
    async (api) => {
      const isValidName = name.actions.validate(name.data.trimmedValue)

      if (!isValidName) return

      await api.locations.updateLocation({
        id,
        requestBody: {
          name: name.data.trimmedValue,
          address: address.data.trimmedValue || null,
        },
      })

      await refresh()

      if (typeof props?.onSuccess === 'function') await props.onSuccess()
    },
    {
      fields: { Name: name, Address: address },
    },
  )

  watch(
    data,
    (data) => {
      name.data.value = data?.name || ''
      address.data.value = data?.address || ''
    },
    { immediate: true },
  )

  return {
    field: {
      name,
      address,
    },

    data: reactive({
      value: data,
      name: computed<string>(() => data.value?.name || ''),
      error,
    }),

    state: reactive({
      pending,
      processing,
    }),

    actions: {
      refresh,
      update,
    },
  }
}
