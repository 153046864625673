export const useCreate = (onSuccess: () => unknown) => {
  const name = useStringField()
  const address = useStringField()

  const { processing, action: submit } = useRequest(
    async (api) => {
      const isValidName = name.actions.validate(name.data.trimmedValue)

      if (!isValidName) return

      await api.locations.createLocation({
        requestBody: {
          name: name.data.trimmedValue,
          address: address.data.trimmedValue || null,
        },
      })

      await onSuccess()
    },
    {
      fields: { Name: name, Address: address },
    },
  )

  return {
    field: {
      name,
      address,
    },

    state: reactive({
      processing,
    }),

    actions: {
      submit,
    },
  }
}
