import type { ApiError, DtoLocationDetails } from '../sdk/api'
import type { LocationsServiceOptions } from './types'
import type { SelectOption } from '~/types'

export const useOptions = async (): Promise<LocationsServiceOptions> => {
  const api = useAPI()

  const { data, pending, error, refresh } = await useAsyncData<
    DtoLocationDetails[],
    ApiError,
    DtoLocationDetails[]
  >(() => api.locations.getLocations(), {
    default: () => [],
  })

  const options = computed<SelectOption<string>[]>(() =>
    data.value.map((location) => ({
      id: location.id,
      text: location.name,
    })),
  )

  return {
    data: reactive({
      options,
      error,
    }),

    state: reactive({ pending }),

    actions: { refresh },
  }
}
